<template>
    <div>
        <OrdersList />
        <LongPressHelp />
    </div>
</template>

<script>
import OrdersList from '@apps/Orders/components/OrdersList'
import pageMeta from '@/mixins/pageMeta'
import LongPressHelp from '@/components/LongPressHelp.vue'
export default {
    mixins: [pageMeta],
    components: {
        OrdersList,
        LongPressHelp
    }
}
</script>